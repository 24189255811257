<template>
  <div>
    <el-card class="editContainer">
      <el-form ref="form" class="vendorFrom" label-position="right" :model="form" label-width="210px">
        <el-form-item label="多地区商家二级商户号设置">
          <!-- <el-radio v-model="form.isMasterAccount" :label="0">各地区平台二级商户号</el-radio> -->
          <el-radio v-model="form.isMasterAccount" :label="1">总账二级商户号</el-radio>
        </el-form-item>
        <el-form-item label="选择二级商户号" prop="orderOvertime" v-show="form.isMasterAccount === 1">
          <el-select class="searchInput" v-model="form.platformSubMerchantId">
            <el-option
              v-for="(item, index) in accountList"
              :key="index"
              :value="item.platformSubMerchantId"
              :label="item.accountName"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveSetting">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountList: [],
      form: {
        isMasterAccount: 1,
        platformSubMerchantId: ''
      }
    }
  },
  created() {
    this.getAccountList()
    this.getAccountDetail()
  },
  methods: {
    getAccountList() {
      this.$http
        .get('boom-center-wechat-service/sysAdmin/platform/sub-merchant/page', { params: { page: 1, size: 1000 } })
        .then(res => {
          this.accountList = []

          res.list.forEach(item => {
            if (item.applymentState === 6) {
              this.accountList.push(item)
            }
          })
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    saveSetting() {
      this.$http
        .post('/boom-center-admin-service/sysAdmin/multiAreaBusiness', {
          isMasterAccount: this.form.isMasterAccount,
          platformSubMerchantId: this.form.platformSubMerchantId
        })
        .then(res => {
          this.$message.success('修改成功')
          this.getAccountDetail()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    // 获取总账设置的信息
    getAccountDetail() {
      this.$http
        .get('/boom-center-admin-service/sysAdmin/multiAreaBusiness/getInfo')
        .then(res => {
          this.form.isMasterAccount = res.isMasterAccount

          if (this.form.isMasterAccount === 1) {
            this.form.platformSubMerchantId = res.platformSubMerchantId
          }

          console.log(this.form)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
  .vendorFrom {
    margin-left: 20px;
    .el-input-number,
    .el-select,
    .el-input {
      width: 250px;
    }

    .assist {
      .assistText;
    }
  }
}
</style>
